export default function Password({ ...props }) {
  return (
    <svg
      width="14"
      height="15"
      viewBox="0 0 14 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M8.65385 8.99998C8.65385 9.66705 8.23172 10.2409 7.6262 10.4957V11.4545C7.6262 11.8217 7.3114 12.1193 6.92308 12.1193C6.53475 12.1193 6.21995 11.8217 6.21995 11.4545V10.4957C5.61444 10.2409 5.19231 9.66705 5.19231 8.99998C5.19231 8.09621 5.96716 7.36362 6.92308 7.36362C7.87899 7.36362 8.65385 8.09621 8.65385 8.99998Z"
        fill="black"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.56733 0H5.3365C4.09389 0 3.08652 0.952413 3.08652 2.12725V4.0909H2.16346C1.58964 4.0909 1.03937 4.30643 0.633615 4.68995C0.227966 5.07358 0 5.59383 0 6.13635V12.9546C0 13.497 0.227966 14.0172 0.633615 14.4008C1.03937 14.7845 1.58964 15 2.16346 15H11.6827C12.2565 15 12.8068 14.7845 13.2125 14.4008C13.6183 14.0172 13.8462 13.497 13.8462 12.9546V6.13635C13.8462 5.59383 13.6183 5.07358 13.2125 4.68995C12.8068 4.30643 12.2565 4.0909 11.6827 4.0909H10.8173V2.12725C10.8173 0.952413 9.80995 0 8.56733 0ZM9.43271 4.0909H4.47112V2.12725C4.47112 1.67541 4.8586 1.30907 5.3365 1.30907H8.56733C9.04523 1.30907 9.43271 1.67541 9.43271 2.12725V4.0909ZM11.6827 5.4545H2.16346C1.97215 5.4545 1.78876 5.52641 1.65355 5.65425C1.56302 5.73984 1.499 5.84591 1.46657 5.96097C1.45062 6.0177 1.44227 6.07662 1.44227 6.13635V12.9546C1.44227 13.1353 1.51833 13.3088 1.65355 13.4366C1.78876 13.5645 1.97215 13.6363 2.16346 13.6363H11.6827C11.7114 13.6363 11.7401 13.6347 11.7684 13.6315C11.8609 13.6211 11.9501 13.5938 12.0311 13.5515C12.0898 13.5208 12.1443 13.4823 12.1927 13.4366C12.2774 13.3565 12.3389 13.2585 12.373 13.1519C12.3933 13.0885 12.4039 13.0221 12.4039 12.9546V6.13635C12.4039 5.95548 12.3279 5.78209 12.1927 5.65425C12.0574 5.52641 11.874 5.4545 11.6827 5.4545Z"
        fill="black"
      />
    </svg>
  );
}
