"use client";

import { FieldData, Tables } from "@/types/database";
import { persist } from "./persist";
import { createOrUpdateScreen } from "@/actions/screens";

type Templates = Tables<"templates">;

interface SavingScreen {
  template_id: Templates['id'],
  data: FieldData[]
}

const persistKey = "saving-screen";

export const savingScreen = {
  get: (): SavingScreen|null => persist.get(persistKey),

  set: (data: SavingScreen): void => persist.set(persistKey, data),

  remove: (): void => persist.remove(persistKey),

  saveScreen: async () => {
    const screen = savingScreen.get();

    if (!screen) {
      throw new Error('Screen not found in persisting data.');
    }

    const response = await createOrUpdateScreen({
      templateId: screen.template_id,
      formData: screen.data
    })

    savingScreen.remove();

    return {
      screen: response?.screen,
      screenUrl: response?.screen ? `account/screens/${response.screen.id}/edit` : '',
    }
  }
};
