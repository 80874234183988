import { ComponentPropsWithoutRef } from "react";
import { UseFormRegister } from "react-hook-form";

interface Props extends ComponentPropsWithoutRef<"input"> {
  type: string;
  name: string;
  className?: string;
  register: UseFormRegister<any>;
  children?: JSX.Element;
}

export default function Input({
  type,
  name,
  children,
  register,
  ...props
}: Props) {
  if (!props.className) {
    props.className = "border border-black text-gray-900 font-light rounded-lg outline-teal-600 block w-full pt-[9px] pb-[10px] pl-10"
  }

  if (!props.autoComplete) {
    props.autoComplete = "off";
  }

  return (
    <div className="relative">
      {children}

      <input
        type={type}
        {...register(name)}
        aria-label={name + "-input"}
        {...props}
      />
    </div>
  );
}
