import NextLink, { LinkProps } from "next/link";

type Props = LinkProps & React.AnchorHTMLAttributes<HTMLAnchorElement> & {
  children: React.ReactNode,
  useDefaultStyle?: boolean,
}

export default function Link({
  children,
  className,
  useDefaultStyle = true,
  ...props
}: Props) {
  if (useDefaultStyle) {
    className += ' text-[#5fd6c1] hover:text-[#2fb59c] hover:underline';
  }

  return (<NextLink
    {...props}
    className={className}
  >
    {children}
  </NextLink>)
}