export const persist = {
  get(key:string): any {
    const data = localStorage.getItem(key);

    if (data) {
      try {
        return JSON.parse(data)?.data;
      } catch (e) {
        console.error(e);
      }
    }

    return null;
  },

  set(key:string, data: any) {
    localStorage.setItem(
      key,
      JSON.stringify({data})
    );
  },

  remove(key:string) {
    localStorage.removeItem(key);
  },
}
