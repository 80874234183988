"use client";

import { useRouter } from 'next/navigation'
import { DM_Sans } from "next/font/google";
import Mail from "@/components/SVG/Mail";
import Password from "@/components/SVG/Password";

import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { z } from "zod";
import { loginSchema } from "./LoginSchema";
import { useState } from "react";
import Checkbox from "@/components/Checkbox";
import Input from "@/components/Input";
import { revalidate, signIn } from "@/actions/auth";
import Button from "../Button";
import Link from "../Link";
import { savingScreen } from '@/utils/saving-screen';

const dmSans = DM_Sans({
  subsets: ["latin"],
  weight: ["300", "400", "600"],
});

interface Props {
  nextUrl?: string;
}

type LoginData = z.infer<typeof loginSchema>;

export default function LoginForm({ nextUrl }: Props) {
  const [error, setError] = useState<string | undefined>();
  const router = useRouter()

  const {
    handleSubmit,
    register,
    formState: { errors, isSubmitting, isDirty, isValid },
  } = useForm<LoginData>({
    resolver: zodResolver(loginSchema),
  });

  const onSubmit = async (formData: {
    email: string,
    password: string
  }) => {
    setError('');
    const {error} = await signIn(formData);

    if (error) {
      console.error(error);
      setError("Invalid username or password.");
      return;
    }

    let redirect = '';

    if (nextUrl === 'screen') {
      try {
        const {screenUrl} = await savingScreen.saveScreen();
        redirect = screenUrl;
      } catch (error) {
        console.error(error);
      }
    } else if (nextUrl) {
      redirect = nextUrl;
    }

    redirect = redirect ? (redirect + '?redirected') : '/templates/new'

    await revalidate(redirect)
    return router.push(redirect);
  };

  return (
    <form
      onSubmit={handleSubmit(onSubmit)}
      className={"space-y-4 md:space-y-6 text-black " + dmSans.className}
    >
      {error && <div className="text-red-600 text-sm">{error}</div>}
      <div>
        <label
          htmlFor={"email"}
          className="block mb-1 font-medium text-gray-900"
        >
          Email
        </label>
        <Input
          register={register}
          type="email"
          name="email"
          placeholder="Enter your email"
          autoComplete="on"
        >
          <Mail className="absolute top-[17px] left-[14px]" />
        </Input>
        {errors?.email && (
          <p className="text-red-600 text-sm">{errors?.email?.message}</p>
        )}
      </div>
      <div>
        <label
          htmlFor={"password"}
          className="block mb-1 font-medium text-gray-900"
        >
          Password
        </label>
        <Input
          register={register}
          type="password"
          name="password"
          placeholder="Enter your password"
        >
          <Password className="absolute top-[15px] left-[14px]" />
        </Input>
        {errors?.password && (
          <p className="text-red-600 text-sm">{errors?.password?.message}</p>
        )}
      </div>
      <div className="flex items-center justify-between">
        <div className="flex items-start">
          <div className="flex items-center h-5">
            <Checkbox name="remember" register={register} />
          </div>
          <div className="ml-3 text-sm">
            <label htmlFor={"remember"}>Remember me</label>
          </div>
        </div>
        <Link href="/reset-password">
          Forgot password?
        </Link>
      </div>
      <Button
        type="submit"
        isLoading={isSubmitting}
      >
        Sign In
      </Button>
    </form>
  );
}
