export default function Mail({...props}) {
  return (
    <svg
      width="14"
      height="12"
      viewBox="0 0 14 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M11.9 0H2.1C1.54305 0 1.0089 0.224706 0.615076 0.624686C0.221249 1.02467 0 1.56716 0 2.13281V9.24219C0 9.80784 0.221249 10.3503 0.615076 10.7503C1.0089 11.1503 1.54305 11.375 2.1 11.375H11.9C12.457 11.375 12.9911 11.1503 13.3849 10.7503C13.7788 10.3503 14 9.80784 14 9.24219V2.13281C14 1.56716 13.7788 1.02467 13.3849 0.624686C12.9911 0.224706 12.457 0 11.9 0ZM2.1 1.42188H11.9C12.0857 1.42188 12.2637 1.49678 12.395 1.6301C12.5262 1.76343 12.6 1.94426 12.6 2.13281L7 5.60219L1.4 2.13281C1.4 1.94426 1.47375 1.76343 1.60503 1.6301C1.7363 1.49678 1.91435 1.42188 2.1 1.42188ZM12.6 9.24219C12.6 9.43074 12.5262 9.61157 12.395 9.7449C12.2637 9.87822 12.0857 9.95312 11.9 9.95312H2.1C1.91435 9.95312 1.7363 9.87822 1.60503 9.7449C1.47375 9.61157 1.4 9.43074 1.4 9.24219V3.75375L6.636 7.00274C6.74241 7.06513 6.86312 7.09798 6.986 7.09798C7.10888 7.09798 7.22959 7.06513 7.336 7.00274L12.6 3.75375V9.24219Z"
        fill="black"
      />
    </svg>
  );
}
