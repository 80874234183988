import { ComponentPropsWithoutRef } from "react";
import { UseFormRegister } from "react-hook-form";

interface Input extends ComponentPropsWithoutRef<"input"> {
  name: string;
  className?: string;
  register?: UseFormRegister<any>;
}

export default function Checkbox({
  name,
  className = '',
  register,
  ...props
}: Input) {
  return (
    <input
      type="checkbox"
      {...(register ? register(name) : {})}
      className={
        "w-4 h-4 text-white border-gray-300 rounded accent-teal-600 border-lg " +
        className
      }
      {...props}
    />
  );
}
